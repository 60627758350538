import React, { Suspense, useState, useEffect, lazy } from 'react';
import { createBrowserRouter, RouterProvider, useNavigation, Outlet } from 'react-router-dom';
import './App.css';
import { Header } from './components/common/Header/Header';
import { Footer } from './components/common/Footer/Footer';
import { LoadingScreen } from './components/loading/LoadingScreen';
import { CybersecurityChatbot } from './components/common/ChatBot/CybersecurityChatbot';
// import { posts } from './components/home/BlogPosts/Posts';

// Lazy load all page components
const Home = lazy(() => import('./Pages/home').then(module => ({ default: module.Home })));
const Services = lazy(() => import('./Pages/Services').then(module => ({ default: module.Services })));
const Cmp4s = lazy(() => import('./components/services/cmp4s/cmp4s').then(module => ({ default: module.Cmp4s })))
const DFI = lazy(() => import('./components/services/dfi/dfi').then(module => ({ default: module.DFI })));
const VAPT = lazy(() => import('./components/services/vapt/vapt').then(module => ({ default: module.VAPT })));
const ISA = lazy(() => import('./components/services/isa/isa').then(module => ({ default: module.ISA })));
const MSSP = lazy(() => import('./components/services/mssp/mssp').then(module => ({ default: module.MSSP })));
const ParticleRing = lazy(() => import('./Pages/three').then(module => ({ default: module.ParticleRing })));
const AboutUs = lazy(() => import('./Pages/company/aboutUs').then(module => ({ default: module.AboutUs })));
const ContactPage = lazy(() => import('./Pages/support/contactUs').then(module => ({ default: module.ContactPage })));
const TeamSection = lazy(() => import('./Pages/company/Team').then(module => ({ default: module.TeamSection })));
const GreyArmour = lazy(() => import('./Pages/products/GreyAmour').then(module => ({ default: module.GreyArmour })));
const Error404Page = lazy(() => import('./Pages/NotFound').then(module => ({ default: module.Error404Page })));
const CSAT = lazy(() => import('./Pages/products/CSAT').then(module => ({ default: module.CSAT})));
const Product = lazy(() => import('./Pages/products/Product').then(module => ({ default: module.Product })));
const HaveBeenHackedForm = lazy(() => import('./Pages/haveBeenHacked').then(module => ({ default: module.HaveBeenHackedForm })));
const HelpResources = lazy(() => import('./Pages/support/HelpResources').then(module => ({ default: module.HelpResources })));
const GuardianWatch = lazy(() => import('./Pages/research/GuardianWatch').then(module => ({ default: module.GuardianWatch })));
const BookDemo = lazy(() => import('./Pages/support/BookDemo').then(module => ({ default: module.BookDemo })));
const BoardOfAdvisors = lazy(() => import('./Pages/company/BoardOfAdvisors').then(module => ({ default: module.BoardOfAdvisors })));
const CyberSecurityReport = lazy(() => import('./Pages/research/CyberSecurityReport').then(module => ({ default: module.CyberSecurityReport })));
const UpcomingEvents = lazy(() => import('./Pages/events/UpcomingEvents').then(module => ({ default: module.UpcomingEvents })));
const WebinarComponent = lazy(() => import('./Pages/webinar').then(module => ({ default: module.WebinarComponent })));
const AwarenessMonth = lazy(() => import('./Pages/events/AwarenessMonth').then(module => ({ default: module.AwarenessMonth })));
const BlogPostPage = lazy(() => import('./Pages/blogs/BlogPostPage').then(module => ({ default: module.BlogPostPage })));
const EastConLanding = lazy(() => import('./Pages/eastcon').then(module => ({ default: module.EastConLanding })));
const SpeakersGrid = lazy(() => import('./Pages/Speakers').then(module => ({ default: module.SpeakersGrid })));

function Layout() {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (navigation.state === 'idle') {
      const timer = setTimeout(() => setIsLoading(false), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsLoading(true);
    }
  }, [navigation.state]);

  return (
    <div className="flex flex-col min-h-screen">
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <>
          <Header />
          <main className="flex-grow pt-[9rem] md:pt-[6rem]">
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </main>
          <CybersecurityChatbot />
          <Footer />
        </>
      )}
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Suspense fallback={<LoadingScreen />}><Error404Page /></Suspense>,
    children: [
      { index: true, element: <Home /> },
      { path: 'services', element: <Services /> },
      { path: 'services/cmp4s', element: <Cmp4s /> },
      { path: 'services/digital-forensics', element: <DFI /> },
      { path: 'services/vapt', element: <VAPT /> },
      { path: 'services/isaudit', element: <ISA /> },
      { path: 'services/mssp', element: <MSSP /> },
      { path: 'three', element: <ParticleRing /> },
      { path: 'company', element: <AboutUs /> },
      { path: 'company/about', element: <AboutUs /> },
      { path: 'company/board_of_advisors', element: <BoardOfAdvisors /> },
      { path: 'company/team', element: <TeamSection /> },
      { path: 'support/contact', element: <ContactPage /> },
      { path: 'support', element: <ContactPage /> },
      { path: 'support/help_resources', element: <HelpResources /> },
      { path: 'support/book-demo', element: <BookDemo /> },
      { path: 'products', element: <Product /> },
      { path: 'products/greyarmour', element: <GreyArmour /> },
      { path: 'products/csat', element: <CSAT /> },
      { path: 'research', element: <CyberSecurityReport /> },
      { path: 'research/cybersecurity_report', element: <CyberSecurityReport /> },
      { path: 'research/guardian_watch', element: <GuardianWatch /> },
      { path: 'events', element: <UpcomingEvents /> },
      { path: 'events/upcoming', element: <UpcomingEvents /> },
      { path: 'events/CMP4S_Webinar', element: <WebinarComponent /> },
      { path: 'events/october-awareness-month', element: <AwarenessMonth /> },
      { path: 'events/october-awareness-month/eastcon', element: <EastConLanding /> },
      { path: 'events/october-awareness-month/eastcon/speakers', element: <SpeakersGrid /> },
      { path: 'have-been-hacked', element: <HaveBeenHackedForm /> },
      { path: 'blog/:id', element: <BlogPostPage /> },
      { path: '*', element: <Error404Page /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;